/** Please refer css.gg for icons **/

.gg-layout-grid {
  transform: scale(var(--ggs, 1.3));
}

.gg-layout-grid,
.gg-layout-grid::after,
.gg-layout-grid::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 1px;
}

.gg-layout-grid::after,
.gg-layout-grid::before {
  content: '';
  position: absolute;
  height: 4px;
  border-left: 4px solid;
  border-right: 4px solid;
}

.gg-layout-grid::before {
  top: 0;
}

.gg-layout-grid::after {
  bottom: 0;
}

/** Maginifying Lens (Search) **/

.gg-search {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.9));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}

.gg-search::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}

/** Sidebar **/

.gg-sidebar,
.gg-sidebar::before {
  display: block;
  box-sizing: border-box;
  height: 16px;
}

.gg-sidebar {
  border-left: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
}

.gg-sidebar::before {
  content: '';
  position: absolute;
  width: 14px;
  border: 2px solid;
  top: 0;
  left: 2px;
}

/** Sidebar Open **/

.gg-sidebar-open {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 16px;
  border: 2px solid;
}

.gg-sidebar-open::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 12px;
  background: currentColor;
  top: 0;
  left: 0;
}

/** Logout / Logoff / Sign out, Shut Down **/

.gg-log-off {
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 16px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-top: 2px solid transparent;
}

.gg-log-off::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 8px;
  width: 2px;
  background: currentColor;
  left: 5px;
  bottom: 6px;
}

.gg-inbox {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  box-shadow: inset 0 -2px 0 0;
  border-radius: 3px;
}

.gg-inbox::after,
.gg-inbox::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  height: 7px;
  width: 7px;
  border-radius: 2px;
  bottom: -2px;
}

.gg-inbox::before {
  left: -2px;
}

.gg-inbox::after {
  right: -2px;
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-up::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-options {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 10px;
  height: 2px;
  box-shadow:
    -3px 4px 0 0,
    3px -4px 0 0;
}

.gg-options::after,
.gg-options::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 100%;
}

.gg-options::before {
  top: -7px;
  left: -4px;
}

.gg-options::after {
  bottom: -7px;
  right: -4px;
}

.gg-math-plus,
.gg-math-plus::after {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
}

.gg-math-plus {
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 0.7));
  width: 16px;
  height: 2px;
}

.gg-math-plus::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

@keyframes spinner-two {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.gg-spinner-two {
  transform: scale(var(--ggs, 0.7));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}

.gg-spinner-two::after,
.gg-spinner-two::before {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  border-radius: 100px;
}

.gg-spinner-two::before {
  animation: spinner-two 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-bottom-color: currentColor;
  border-top-color: currentColor;
}

.gg-spinner-two::after {
  border: 3px solid;
  opacity: 0.2;
}

.gg-check-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}

.gg-check-o::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg-danger {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.gg-danger::after,
.gg-danger::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}

.gg-danger::after {
  top: 2px;
  height: 8px;
}

.gg-danger::before {
  height: 2px;
  bottom: 2px;
}

.gg-format-slash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(25deg) scale(var(--ggs, 0.7));
  width: 2px;
  height: 14px;
  background: currentColor;
  border-radius: 3px;
}

.gg-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-right::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}

.gg-format-slash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(25deg) scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
  border-radius: 3px;
}

.gg-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-left::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 4px;
}

.gg-add {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
}

.gg-add::after,
.gg-add::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}

.gg-add::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-menu-grid-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.8));
  width: 16px;
  height: 16px;
}

.gg-menu-grid-o::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow:
    0 6px 0,
    6px 6px 0,
    12px 6px 0,
    6px 12px 0,
    12px 12px 0,
    6px 0 0,
    12px 0 0,
    0 12px 0;
  border-radius: 22px;
}

.gg-arrow-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
}

.gg-arrow-left::after,
.gg-arrow-left::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}

.gg-arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}

.gg-arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}
