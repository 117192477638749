.data-table {
  border: 1px solid var(--border-light) !important;
  border-radius: 6px;
  overflow: hidden;
  background: var(--bg-card);
}

.wtable {
  min-width: 100%;
  width: 100%;
  cursor: default;
}

.wtable thead {
  background: var(--bg-primary);
  border-bottom: 1px solid var(--border-faded);
}

.wtable th,
.wtable td {
  padding: 0.5rem 0.7rem;
  max-width: 320px;
}

.wtable thead {
  background: var(--bg-table-head);
}

.wtable thead th {
  font-size: 0.6rem;
  font-weight: 600;
  resize: horizontal;
  color: var(--text-primary);
  background: var(--bg-table-head);
  border-bottom: 1px solid var(--border-faded);
}

.wtable tr {
  font-size: 0.75rem;
  border-bottom: 1px solid var(--border-faded);
  background: var(--bg-primary);
}

.wtable tr:last-child {
  border: 0;
}

.datatable-wrapper {
  background: var(--bg-card);
  padding: 1.2rem 0;
  border-radius: 8px;
  border: 1px solid var(--border-main);
}

.datatable-wrapper .section {
  padding: 0 1.2rem;
}

.datatable-wrapper .data-table {
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.table-actions {
  display: flex;
  align-items: center;
  gap: 14px;
}

.table-actions button::after {
  display: none;
}

.table-actions button.show {
  background: #f5f5f5;
}

.btn-filters {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.btn-filters button {
  flex: 1;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-right: 1px solid #ccc;
}

.btn-filters button:last-child {
  border: 0;
}

.data-actions {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 4px 10px;
}

.data-actions:hover,
.data-actions:active {
  border: 1px solid #ccc;
}

.card-table {
  --table-space: 1.2rem;
  padding: var(--table-space);
  padding-bottom: 0;
}

.card-table .paginator {
  padding: 0.8rem 0;
}

.card-table .data-table {
  border-radius: 0;
  margin-left: calc(var(--table-space) * -1);
  margin-right: calc(var(--table-space) * -1);
  border-left: 0;
  border-right: 0;
}

.card-table .wtable th:first-child,
.card-table .wtable td:first-child {
  padding-left: var(--table-space);
}

.data-table.scrollable {
  overflow: auto;
  padding-bottom: 10px;
  resize: vertical;
  border: 0;
  border-bottom: 1px solid var(--border-main);
}

.data-table.scrollable .wtable {
  width: max-content;
  border-collapse: separate;
  border-spacing: 0;
}

.data-table.scrollable .wtable tbody tr td,
.data-table.scrollable .wtable tbody tr th {
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-faded);
}

.data-table.scrollable .wtable tbody tr:last-child td {
  border-bottom: none;
}

.data-table.scrollable .wtable thead {
  position: sticky;
  top: 0;
  z-index: 5;
}
