body {
  color: #000;
  background: #eee;

  --theme-color: #05f;
  --theme-color-dark: #004ee7;
  --theme-color-light: #09f;
  --theme-color-faded: #def;
  --theme-bg-text: #fff;

  --bg-primary: #fff;
  --bg-card: #fff;
  --bg-highlight: #def;
  --bg-table-head: #fafafa;
  --bg-header: #05f;
  --bg-header-active: #004ee7;

  --text-primary: #000;
  --text-link: #06f;
  --text-faded: #888;
  --text-disabled: #ccc;
  --text-header: #fff;
  --text-header-active: #fff;

  --border-primary: #bbb;
  --border-main: #ddd;
  --border-light: #eee;
  --border-faded: #f5f5f5;

  /* SIDEBAR */
  --sidebar-bg: #fff;
  --sidebar-border: #ddd;

  --sidebar-item: #000;
  --sidebar-item-hover: #e2f2ff;
  --sidebar-item-active: var(--text-link);
  --sidebar-item-bg-active: #e2f2ff;

  --sidebar-group-title: #888;
  --sidebar-group-border: #eee;

  /** SCROLLBARS **/
  --scrollbar-thumb: #0004;
}

body.dark {
  color: #fff;
  background: #000;

  --theme-color: #000;
  --theme-color-dark: #000;
  --theme-color-light: #444;
  --theme-color-faded: #666;
  --theme-bg-text: #fff;

  --bg-primary: #000;
  --bg-card: #111;
  --bg-highlight: #555;
  --bg-table-head: #000;
  --bg-header: #111;
  --bg-header-active: #222;

  --text-primary: #eee;
  --text-link: #ffeb3b;
  --text-faded: #888;
  --text-disabled: #444;
  --text-header: #fff;
  --text-header-active: #ffeb3b;

  --border-primary: #444;
  --border-main: #333;
  --border-light: #222;
  --border-faded: #111;

  /* SIDEBAR */
  --sidebar-bg: #000;
  --sidebar-border: #000;

  --sidebar-item: #fff;
  --sidebar-item-hover: #333;
  --sidebar-item-active: #ffeb3b;
  --sidebar-item-bg-active: #555;

  --sidebar-group-title: #999;
  --sidebar-group-border: #111;

  /** SCROLLBARS **/
  --scrollbar-thumb: #fff3;
}
