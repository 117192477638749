:root {
  --circle-size: clamp(1.5rem, 2vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.grecaptcha-badge {
  display: none !important;
}

.cs-upload-btn {
  position: absolute;
  right: 2px;
  top: 3px;
  background: var(--theme-color-faded);
  border: 1px solid var(--theme-color);
}

.refresh-btn {
  padding: 5px;
  background-color: var(--theme-color-faded);
  position: relative;
  border-radius: 0.25rem;
}

.refresh-btn:focus {
  border: 1px solid var(--theme-color);
}

.refresh-btn ng-icon {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.dashed-top {
  border-top: 1px dashed var(--border-main);
  padding: 1rem 0;
}
