.btn {
  font-size: 0.9rem;
  border-radius: 4px;
}

.btn-primary {
  background: #07f;
  background: var(--theme-color);
}

.btn-light {
  border: 1px solid #ddd;
  background: #fafafa;
  color: #222;
}

.btn-light:hover {
  background-color: #e7e7e7;
}

.btn-sm {
  padding: 0.4rem 0.7rem;
  font-size: 0.8rem;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.25rem 0.55rem;
}

.btn.full {
  width: 100%;
}

.btn.flex,
button.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.btn-link {
  text-decoration: none;
  color: #06f;
}

.btn-text {
  padding: 0;
  color: #555;
  cursor: pointer;
}

.btn-text:hover {
  color: #07f;
}

.btn-tiny {
  padding: 0;
  color: #777;
  font-size: 0.8rem;
  text-decoration: none;
  border: 0;
}

.btn-tiny.flex {
  gap: 2px;
}

.btn-tiny:hover,
.btn-tiny:active {
  color: #06f;
  outline: none;
  border: 0;
  background: inherit !important;
}

.btn-icon {
  padding: 0.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
}

.btn-icon:hover {
  background: #f5f5f5;
  border: 1px solid;
}

.btn-big {
  padding: 0.6rem 0.5rem;
  font-size: 0.9rem;
}

.text-link {
  color: #555;
  cursor: pointer;
  border-bottom: 1px dashed;
  border-bottom-color: initial;
}

.text-link:hover {
  color: #07f;
  border-bottom: 1px solid;
}

.text-link-primary {
  color: #07f;
  cursor: pointer;
}
